import React, { useContext } from "react"
import { Link } from "gatsby"
import Template from "../../components/template"
import "../../styles/blog/blogpost.css"
import { LesbianSeeingContent } from "../../content/blog/lesbian-seeing"
import { SettingsContext } from "../../contexts/settings"

const LesbianSeeingPage = () => {
    const [settings, ] = useContext(SettingsContext)
    const content = LesbianSeeingContent[settings.language]
    return (
        <Template currentPage="blog">
            <section>
            <div>
                <h1>
                    <Link to="/blog#lesbian-seeing" class="back-arrow">{content.arrow}</Link>
                    {content.headline}
                </h1>
                <p>{content.date}</p>
                
                <p>
                    <b>
                        {content.firstFirst}
                        <a className="link-bold" href="https://hu-berlin.academia.edu/EvavonRedecker" target="_blank" rel="noopener noreferrer">{content.firstLinkFirst}</a>
                        {content.firstSecond}
                        <a className="link-bold" href="https://lucyduggan.com/" target="_blank" rel="noopener noreferrer">{content.firstLinkSecond}</a>
                    </b>
                </p>
                <p className="text-middle text-indented">
                    {content.secondFirst}
                    <Link className="link-middle" to="/blog/second-sight">{content.secondLink}</Link>
                    {content.secondSecond}
                </p>
                <p className="text-middle text-indented">
                    {content.third}
                    <a className="link-middle" href="https://www.facebook.com/events/892243821305440/" target="_blank" rel="noopener noreferrer">{content.thirdLink}</a>
                </p>
                <p>{content.fourth}</p>
                <p>{content.fifth}</p>
                <p>{content.sixth}</p>
                <p>{content.seventh}</p>
                <p className="text-middle text-indented">{content.eighth}</p>
                <p>{content.ninth}</p>
                <p>{content.tenth}</p>
                <p>{content.eleventh}</p>
                <p>{content.twelfth}</p>
                <p>{content.thirteenth}</p>
                <p className="text-middle text-indented">
                    {content.fourteenthFirst}<br/>
                    {content.fourteenthSecond}<br/>
                    {content.fourteenthThird}<br/>
                    {content.fourteenthFourth}<br/>
                    {content.fourteenthFifth}<br/>
                    {content.fourteenthSixth}<br/>
                    {content.fourteenthSeventh}<br/>
                    {content.fourteenthEighth}<br/>
                    {content.fourteenthNinth}<br/>
                    {content.fourteenthTenth}
                </p>
                <p>{content.fifteenth}</p>
                <p>{content.sixteenth}</p>
                <p>{content.seventeenth}</p>
                <p className="text-middle text-indented">{content.eighteenth}</p>
                <p className="text-middle text-indented">{content.nineteenth}</p>
                <p className="text-middle text-indented">{content.twentieth}</p>
                <p className="text-middle text-indented"><b>{content.twentyfirst}</b></p>
                <p>{content.twentysecond}</p>
                <p>{content.twentythird}</p>
                <p>{content.twentyfourth}</p>
                <p className="text-middle text-indented">
                    {content.twentyfifthFirst}<br/>
                    {content.twentyfifthSecond}<br/>
                    {content.twentyfifthThird}<br/>
                    {content.twentyfifthFourth}<br/>
                    {content.twentyfifthFifth}
                </p>
                <p className="text-middle text-indented">{content.twentysixth}</p>
                <p className="text-middle text-indented">{content.twentyseventh}</p>
                <p>{content.twentyeighth}</p>
                <p>{content.twentyninth}</p>
                <p>{content.thirtieth}</p>
                <p>{content.thirtyfirst}</p>
                <p >
                    <Link className="link" to="/blog/second-sight">
                        <b>{content.thirtysecond}</b>
                    </Link>
                </p>
                <p className="end-of-site">{content.thirtythird}</p>
            </div>    
            </section>
        </Template >
    )
}

export default LesbianSeeingPage