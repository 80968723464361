const LesbianSeeingContent = {
    german: {
        date: "2. November 2020",
        arrow: "BLOG << ",
        headline: "Lesbian Seeing",
        firstFirst: "A guest contribution by ",
        firstLinkFirst: "Eva von Redecker",
        firstSecond: " (translated by ",
        firstLinkSecond: "Lucy Duggan)",
        secondFirst: "nOu’s remark: Lucy Duggan and Eva von Redecker planned to make the following text by Eva and a ",
        secondLink: "reply by Lucy, which you can read by clicking here",
        secondSecond:", the entry points for a lecture performance during Nocturnal Unrest. However, due to the latter’s postponement, Eva and Lucy won’t be able to perform which we deeply regret. We’re grateful that, instead, they provided us with the original texts, granting us invaluable insight into their philosophical exchange and their perspectives on what we perceive depending on who we are – and what might remain in the dark.",
        third: "Starting in November, nOu is offering a digital seminar series (in German language) based on their exchange including a joint discussion with Eva and Lucy. ",
        thirdLink: "Click here for more information.",
        fourth: "A while ago I was sitting at our large garden table with two colleagues whom I’d invited to the post-socialist countryside. As usual, I was attempting to play the perfect housewife, hostess and butler all at once. So, along with the aperitif, I’d also brought a mortar outside, full of salt, garlic and pine nuts, and while we wondered aloud how it was possible that even though there was not a single university chair of feminist philosophy in Germany, right-wingers were still gibbering about a gender-ideological takeover of the universities, I endeavoured to crush a basil plant into pesto. We also discussed whether I should write a text on lesbian visibility for a friend’s edited volume and came to the conclusion that I should, but with the topic shifted: lesbian seeing. These two colleagues are wonderful guests, the sort who take time to experience their surroundings even during a heated discussion, feeling the sunshine and taking in the crumbling distillery beside the house. I winked at the two of them and pointed at the heavy earthenware mortar. And? Are you thinking of Zami? A blissful smile darted across both faces. Of course.",
        fifth: "I didn’t really have to ask. But then, I wouldn’t have asked at all if someone had been there who would have needed a long-winded explanation that Zami. A New Spelling of My Name is an autobiographical novel by the African American philosopher Audre Lorde, with a stunning scene in which the narrator describes the pleasure of crushing spices in a mortar as a young girl, until her mother harshly wrenched her out of her trance. Not all lesbians have read Zami. The lesbian gaze does not have one unambiguous defining feature. It is an endless web of perspectives, in which parts of our experiences keep overlapping.",
        sixth: "According to Simone de Beauvoir, one is not born a woman, but becomes one. Lesbians are neither born women, nor do they ever entirely become women. But they are also not born lesbians. And certainly not made into lesbians by society. As the film theorist Teresa de Lauretis always puts it so beautifully, no one becomes a lesbian all by herself. It takes (at least) two women. Two women in themselves are still not enough to make a lesbian, though. It depends on how they see each other. And if one of them never stops seeing that way after that moment, she has become a lesbian.",
        seventh: "Hélène Cixous – dramatist, poet, philosopher – wrote a fantastic text about this process of learning to see. It appears to be about sight as such, and specifically the experience of overcoming extreme shortsightedness through an eye operation.",
        eighth: "“Yes, said the world. Yes said the timid bell tower behind the buildings. Yes I’m coming said one window then another. Is seeing the supreme enjoyment? Or else is it: no-longer-not seeing? Visible birds passed from right to left in the sky flotillas of clouds ran from left to right, it had never been seen! Come, future, come you coming ceaselessly, never arriving, come, coming! It didn’t stop coming, apparitioning. Apparitioning carried on. That’s what was transporting her: the step of Apparition. Coming to See. And who is coming? You or I?”",
        ninth: "Cixous’ genius as a writer reveals itself in her ability to write about seeing in general, but without leaving any doubt that she is writing about lesbian seeing. That she can capture the essence of something so general as vision, by intensifying the metaphors of female-to-female eroticism which carry her entire body of work. How could anyone have regarded that eroticism as something separate and particular? Precisely.",
        tenth: "Radical philosophers have long been fascinated by this same approach: the operation which makes use of a supposedly specific minority perspective to capture a supposedly general notion in a new way. Contrary to a politics of recognition, which assures us that we, too, are now permitted to see ourselves as part of the whole, they insist that if we start from a part which doesn’t fit, we can make out a better whole. Who wouldn’t want to be able to see like that – to stop not seeing, like that?",
        eleventh: "We can understand this operation in various different ways. In Monique Wittig’s terms, a minority author can only be effective if she succeeds in making the minority perspective generally valid. While Wittig emphasises the process of claiming and rearranging the whole, Judith Butler responds by drawing out the connection between the gesture of claiming and the destabilisation of the supposed whole. Through this, she also questions universality itself, the overbearing and authoritative gesture of which she wants to resist.",
        twelfth: "However, as a philosopher, Butler is imagining a different kind of generalising from the writer Wittig. Writers do not generalise by turning to ever greater abstraction, but by describing a whole world from particular perspectives. And it is not as if the world could be described from every perspective. The fact that it is possible to capture something even approaching a whole from one perspective says something about the validity, about the potential of that perspective. From inside the closet, we only see a strip of white wall. Descriptions have to invite continuation in such a way that they capture ever wider segments of the world. My girlfriend described how the world appeared from the balcony of her new flat after she moved in: the pigeons sit there and bicker, but in the evening the seagull turns up. Seagulls are great. They have lots of different screams. They can sound like a cat, like lesbians having sex, or like an annoying kid. To start with I didn’t know that it was always the seagull. On the floor below are two lesbian grandmas. They are very cute and always do the crossword over breakfast. Opposite is a non-lesbian. She’s also pretty cool, she always washes up while wearing a bikini and smoking. And those guys over there at least have a gay towel. These impressions are fragments of lesbian seeing, the beginnings of a description which claims that the world could also fit into our patterns rather than the usual ones.",
        thirteenth: "Since reading Wittig’s much more abstract novels, the most rigorous work of lesbian universalisation I have come across is the 2016 debut of the British barrister Jules Grant. We Go Around In The Night And Are Consumed By Fire switches genres freely, from gangster pulp fiction to socially critical study of milieu, adopting an ambitious montage technique. The streams of consciousness of the protagonist, Donna, and her goddaughter Aurora nevertheless push the story forward, making it as compelling as a TV drama. My beloved novelist translator, with her Oxford doctorate, and I had a telling argument about the language of the novel. She claimed it was highly crafted poetic stylisation. I argued for authentic street slang and insinuated that because of her middle class roots, she had no idea how her mother tongue sounded in “Manchester’s mean streets”, where the blurb situated the story. She just did not know how to classify the text other than as an alienated form of elevated language. Or was I too snobbish to categorise a text with such a density of swearwords and obscenities as poetic, and that was why I relegated it to authenticity, authenticity of the other class? The two linguist friends we consulted pointed out that in a novel, even authenticity was crafted by poetic means, and did find lots of samples of realistic street-wisdom in the text. One of them even knew how to interpret the scene which gives the book its title:",
        fourteenthFirst: "“Bang on the border, we get to the bridge. Looks good, the tag, even in the dark.",
        fourteenthSecond: "Hey look, whoops Lise, that’s us.",
        fourteenthThird: "Read it me, says Sonn.",
        fourteenthFourth: "I read it out.",
        fourteenthFifth: "What the fuck does that mean? says Sonn.",
        fourteenthSixth: "How d’you get it up there? goes Lise.",
        fourteenthSeventh: "I point to the metal ledge over the carriageway. One hand, hang on with the other",
        fourteenthEighth: "Took four cans, I says.",
        fourteenthNinth: "Tricksy, says Lise. Shouldn‘t that be “We Are Consumed”?",
        fourteenthTenth: "Sonn snorts. You wanna swing from that bridge, Lise, make some alterations, no problemo, she says.”",
        fifteenth: "Apart from the fact that this could be a sneaky comment from the author to her editors, who were probably reluctant to embrace the difficult title We Go Around In The Night And Are Consumed By Fire, the scene also reflects the inscribing of class identity in language. English schools, which in any case have a bad reputation when it comes to teaching grammar, drum the basic rule into their pupils’ heads that every verb requires a subject. Anyone who leaves before the sixth form will tend to correct such elliptical, but perfectly acceptable constructions as “We go around and are consumed”. One is not born uneducated, but becomes so. And the more someone is affected by this, the more she has to cultivate virtuoso strategies to assert herself. The last word, and the punchline, goes to Sonn here, whose short-sightedness or illiteracy meant that she was not even able to decipher the words in the first place.",
        sixteenth: "But Donna’s gang of lesbians do not only cooperate when it comes to graffiti. They run a flourishing business producing ecstasy and selling it in the leading clubs in their territory. Their business is disguised as a cleaning firm which not only works as a front, but also helps with the acquisition of the necessary chemicals. The gang are disciplined, only breaking the laws they need to break for their business. But there are quite a few of those, given that the business includes defending the gang’s territory and reputation against rival groups. The novel begins with a hair-raising torture scene, in which the women punish a member of another gang for straying too far into their area the previous evening. With a flick-knife and a biro, they spontaneously tattoo a big “C” on the young man’s stomach, while he is tied up. When it comes to the question of what the C stands for, a question which any reader with a passing knowledge of psychoanalysis would not even ask, the characters weigh up the alternatives “cunt” and “Carla” – the one who carved out the sign.",
        seventeenth: "Which leads us to the text’s centre of gravity: Carla. Carla is the mother of Aurora, she is Donna’s most significant relationship, and her household is the headquarters of the gang. Through Carla, the moth logic of the title – whirring through the night and being devoured by flames – becomes a manifesto. Donna remembers sitting in the gloomy, overgrown backyard at Carla’s house:",
        eighteenth: "“I look over at the pot with the clematis that just wouldn’t grow no matter what she [Carla] did. Clematis clitoris she said when we saw it in the garden centre, on account of all the petals were so fragile, and every shade of purple and pink you could think. To me that’s just bad taste, but Carla never gave a toss about stuff like that, just laughed right at me, Hey prudie, she says.",
        nineteenth: "She was proper mad when we got it home and all the petals fell off, reckoned they shouldn’t be allowed to do that, B&Q, selling people stuff that only grows right somewhere else. False Pretences, she called it. It’s too dark here, she said, things need sun.",
        twentieth: "Then I’m thinking about that time we took the bikes all the way to Newquay, just to see Fistral Beach, and how as soon as we got there she stripped right off on the beach not even caring, spinning round and round like a noon, nut-brown and just this black triangle of hair, arms held up to the sun. How I told her Chrissakes at least put your boxers on, they’ll be selling tickets next. How she didn’t want to come home.",
        twentyfirst: "Things need sun.”",
        twentysecond: "Carla is exuberance and vivacity. When it comes to the unwritten rules of the gang, she does not just break more than is necessary, but everything which can be broken – for instance, when she starts an affair with the girlfriend of a rival leader and then unceremoniously kidnaps the woman and her two small children from the gang leader’s flat. Donna may smell trouble, but as always, she makes the plan which is needed to fulfill Carla’s wishes. After the trouble has arrived and Carla is shot in a club, she truly becomes the heart of the story. Everything revolves around the warmth and the danger which Carla represented. As for how Donna goes on to burn her skilful fingers and whether she, Aurora and the others escape at all – rather than spoiling the story here, I urge you to read the book.",
        twentythird: "Leading on from the clematis/clitoris scene, however, we can make out the structure of representation that lends the book its uniqueness. The magic of the novel rests on the tension between hyper-realistic and completely fantastical elements. One dimension which emerges is the detailed analysis of class (and underclass). Grant constructs a study of institutions, both official and outside the law – school, the care system and jail on the one hand, and criminal network, drug gangs and precarious household on the other. Because almost all the characters are female, an unusual focus opens up on a shadowy milieu which is otherwise presented stereotypically. Reproductive work does not take place noiselessly behind the backs of the heroines. It is not just necessary to keep the business running and the enemies in check: there is also a nine-year-old child to look after, along with a grandmother who has become bed-bound from self-negligence and alcoholism.",
        twentyfourth: "That is one side of the story, aligned with the gloomy backyard. On the other hand, the book is suffused with a willful exaggeration of the lesbian dimension. Even if not all the characters are lesbians, the whole world does seem to be lesbian. Bad taste or not – here we understand the clematis through the clitoris, rather than explaining sex via the birds and the bees. Maybe the term “hyperbolic realism” could apply to this. Or “tuned realism” in the sense of “tuning” a car. Surpassing reality with its own means, or: getting something out of it for which it was not constructed. The novel refers to various basic lesbian truths without discussing or translating them further. Donna takes note of the dirty fingernails of a book seller and thinks “she’s either straight or a minger”. The child is brought up lesbian and every town contains well-organised mafia structures which decide the outcome of battles, keep women’s refuges going, and organise weapons. In the finale of the novel, when Donna’s plan works out and her gang not only succeeds in playing off the two rival male gang leaders against each other, but also in luring them to the same remote place, Sonn signals with a torch. From all directions, from behind every hill, a troop of Amazons flashes back.",
        twentyfifthFirst: "“Where’s the back-up, I ask.",
        twentyfifthSecond: "Sonn takes up the torch, flashes once at the dark, then draws and S in the air.",
        twentyfifthThird: "I knock the torch out of her hand, What the fuck are you doing?",
        twentyfifthFourth: "Look, she says.",
        twentyfifthFifth: "All over the hills there’s lights flashing, going off like silent paparazzi bulbs. Each one a zigzag, then an imprint blue in the air that hangs on for a second, just long enough to read. Flash: J. Flash A, flash M, flash P, flash S flash G, flash, flash. Until there’s light and letters appearing everywhere in the dark. Dozens of them.",
        twentysixth: "Then it’s dark again.",
        twentyseventh: "All over the hillside, says Sonn. I can just make out her grin. Next hill too. In case we need them […]. I told you I‘d sort back-up.”",
        twentyeighth: "What a fantastic thought. When there are so many beacons, maybe the moths won’t be consumed any longer: they’ll be held in balance, equidistant from the lights in all directions of the night. Two women can, as Lauretis says, make a lesbian. But only dozens of different lesbian hordes can secure lesbian seeing.",
        twentyninth: "I wanted to offer this scene as the climactic point in the displacement of reality through lesbian universalisation. Where did they all come from – there aren’t even that many of us. And we certainly aren’t so organised that it’s constantly possible to get hold of anything that’s needed and somebody even knows a judge.",
        thirtieth: "But then: what do I know? Is lesbian universalisation really a fantastical stylistic device at this point? Hyperbole? Does it really stand in contrast to the social realistic axis, to the analyses of state institutions of welfare and punishment? Do I have any idea about drug cartels in British cities? About women’s rugby teams? If I can write a text in which only lesbians are quoted, inspired and annotated by a horde of lesbian academics and friends, why shouldn’t a dealer be able to organise her gang in exactly the same way? Why is it unrealistic that she almost exclusively comes across lesbians in her daily life – at least when it comes to the encounters worth mentioning? Where exactly am I sitting, and who am I writing for?",
        thirtyfirst: "Maybe the only really fantastical thing about We Go Around In The Night And Are Consumed By Fire is that lesbian seeing is simply presupposed, rather than being gently mediated. This is not about creating visibility or about outing one character after another. It’s about opening up a completely different space of appearance, in which, if you believe your eyes, you’ll see from a lesbian perspective. Following Cixous, we could say that the threshold of appearance has been crossed, and we have entered the realm of sight. Seen in this light, it is as if we were stumbling around in the dark until now, letting ourselves become fixated on a pitiful few rays of light. Karl Marx (that old lesbian) pointed out long ago that liberal rights to equality are much like Carla’s clematis: they’re no use, as long as there’s too little light in your backyard.",
        thirtysecond: "Click here for Lucy Duggans reply.",
        thirtythird: "This text first appeared in German in the anthology „Lesben raus! Für mehr lesbische Sichtbarkeit.“ (Querverlag 2018, ed. Stephanie Kuhnen)."
    },
    english: {
        date: "November 2, 2020",
        arrow: "BLOG << ",
        headline: "Lesbian Seeing",
        firstFirst: "A guest contribution by ",
        firstLinkFirst: "Eva von Redecker",
        firstSecond: " (translated by ",
        firstLinkSecond: "Lucy Duggan)",
        secondFirst: "nOu’s remark: Lucy Duggan and Eva von Redecker planned to make the following text by Eva and a ",
        secondLink: "reply by Lucy, which you can read by clicking here",
        secondSecond:", the entry points for a lecture performance during Nocturnal Unrest. However, due to the latter’s postponement, Eva and Lucy won’t be able to perform which we deeply regret. We’re grateful that, instead, they provided us with the original texts, granting us invaluable insight into their philosophical exchange and their perspectives on what we perceive depending on who we are – and what might remain in the dark.",
        third: "Starting in November, nOu is offering a digital seminar series (in German language) based on their exchange including a joint discussion with Eva and Lucy. ",
        thirdLink: "Click here for more information.",
        fourth: "A while ago I was sitting at our large garden table with two colleagues whom I’d invited to the post-socialist countryside. As usual, I was attempting to play the perfect housewife, hostess and butler all at once. So, along with the aperitif, I’d also brought a mortar outside, full of salt, garlic and pine nuts, and while we wondered aloud how it was possible that even though there was not a single university chair of feminist philosophy in Germany, right-wingers were still gibbering about a gender-ideological takeover of the universities, I endeavoured to crush a basil plant into pesto. We also discussed whether I should write a text on lesbian visibility for a friend’s edited volume and came to the conclusion that I should, but with the topic shifted: lesbian seeing. These two colleagues are wonderful guests, the sort who take time to experience their surroundings even during a heated discussion, feeling the sunshine and taking in the crumbling distillery beside the house. I winked at the two of them and pointed at the heavy earthenware mortar. And? Are you thinking of Zami? A blissful smile darted across both faces. Of course.",
        fifth: "I didn’t really have to ask. But then, I wouldn’t have asked at all if someone had been there who would have needed a long-winded explanation that Zami. A New Spelling of My Name is an autobiographical novel by the African American philosopher Audre Lorde, with a stunning scene in which the narrator describes the pleasure of crushing spices in a mortar as a young girl, until her mother harshly wrenched her out of her trance. Not all lesbians have read Zami. The lesbian gaze does not have one unambiguous defining feature. It is an endless web of perspectives, in which parts of our experiences keep overlapping.",
        sixth: "According to Simone de Beauvoir, one is not born a woman, but becomes one. Lesbians are neither born women, nor do they ever entirely become women. But they are also not born lesbians. And certainly not made into lesbians by society. As the film theorist Teresa de Lauretis always puts it so beautifully, no one becomes a lesbian all by herself. It takes (at least) two women. Two women in themselves are still not enough to make a lesbian, though. It depends on how they see each other. And if one of them never stops seeing that way after that moment, she has become a lesbian.",
        seventh: "Hélène Cixous – dramatist, poet, philosopher – wrote a fantastic text about this process of learning to see. It appears to be about sight as such, and specifically the experience of overcoming extreme shortsightedness through an eye operation.",
        eighth: "“Yes, said the world. Yes said the timid bell tower behind the buildings. Yes I’m coming said one window then another. Is seeing the supreme enjoyment? Or else is it: no-longer-not seeing? Visible birds passed from right to left in the sky flotillas of clouds ran from left to right, it had never been seen! Come, future, come you coming ceaselessly, never arriving, come, coming! It didn’t stop coming, apparitioning. Apparitioning carried on. That’s what was transporting her: the step of Apparition. Coming to See. And who is coming? You or I?”",
        ninth: "Cixous’ genius as a writer reveals itself in her ability to write about seeing in general, but without leaving any doubt that she is writing about lesbian seeing. That she can capture the essence of something so general as vision, by intensifying the metaphors of female-to-female eroticism which carry her entire body of work. How could anyone have regarded that eroticism as something separate and particular? Precisely.",
        tenth: "Radical philosophers have long been fascinated by this same approach: the operation which makes use of a supposedly specific minority perspective to capture a supposedly general notion in a new way. Contrary to a politics of recognition, which assures us that we, too, are now permitted to see ourselves as part of the whole, they insist that if we start from a part which doesn’t fit, we can make out a better whole. Who wouldn’t want to be able to see like that – to stop not seeing, like that?",
        eleventh: "We can understand this operation in various different ways. In Monique Wittig’s terms, a minority author can only be effective if she succeeds in making the minority perspective generally valid. While Wittig emphasises the process of claiming and rearranging the whole, Judith Butler responds by drawing out the connection between the gesture of claiming and the destabilisation of the supposed whole. Through this, she also questions universality itself, the overbearing and authoritative gesture of which she wants to resist.",
        twelfth: "However, as a philosopher, Butler is imagining a different kind of generalising from the writer Wittig. Writers do not generalise by turning to ever greater abstraction, but by describing a whole world from particular perspectives. And it is not as if the world could be described from every perspective. The fact that it is possible to capture something even approaching a whole from one perspective says something about the validity, about the potential of that perspective. From inside the closet, we only see a strip of white wall. Descriptions have to invite continuation in such a way that they capture ever wider segments of the world. My girlfriend described how the world appeared from the balcony of her new flat after she moved in: the pigeons sit there and bicker, but in the evening the seagull turns up. Seagulls are great. They have lots of different screams. They can sound like a cat, like lesbians having sex, or like an annoying kid. To start with I didn’t know that it was always the seagull. On the floor below are two lesbian grandmas. They are very cute and always do the crossword over breakfast. Opposite is a non-lesbian. She’s also pretty cool, she always washes up while wearing a bikini and smoking. And those guys over there at least have a gay towel. These impressions are fragments of lesbian seeing, the beginnings of a description which claims that the world could also fit into our patterns rather than the usual ones.",
        thirteenth: "Since reading Wittig’s much more abstract novels, the most rigorous work of lesbian universalisation I have come across is the 2016 debut of the British barrister Jules Grant. We Go Around In The Night And Are Consumed By Fire switches genres freely, from gangster pulp fiction to socially critical study of milieu, adopting an ambitious montage technique. The streams of consciousness of the protagonist, Donna, and her goddaughter Aurora nevertheless push the story forward, making it as compelling as a TV drama. My beloved novelist translator, with her Oxford doctorate, and I had a telling argument about the language of the novel. She claimed it was highly crafted poetic stylisation. I argued for authentic street slang and insinuated that because of her middle class roots, she had no idea how her mother tongue sounded in “Manchester’s mean streets”, where the blurb situated the story. She just did not know how to classify the text other than as an alienated form of elevated language. Or was I too snobbish to categorise a text with such a density of swearwords and obscenities as poetic, and that was why I relegated it to authenticity, authenticity of the other class? The two linguist friends we consulted pointed out that in a novel, even authenticity was crafted by poetic means, and did find lots of samples of realistic street-wisdom in the text. One of them even knew how to interpret the scene which gives the book its title:",
        fourteenthFirst: "“Bang on the border, we get to the bridge. Looks good, the tag, even in the dark.",
        fourteenthSecond: "Hey look, whoops Lise, that’s us.",
        fourteenthThird: "Read it me, says Sonn.",
        fourteenthFourth: "I read it out.",
        fourteenthFifth: "What the fuck does that mean? says Sonn.",
        fourteenthSixth: "How d’you get it up there? goes Lise.",
        fourteenthSeventh: "I point to the metal ledge over the carriageway. One hand, hang on with the other",
        fourteenthEighth: "Took four cans, I says.",
        fourteenthNinth: "Tricksy, says Lise. Shouldn‘t that be “We Are Consumed”?",
        fourteenthTenth: "Sonn snorts. You wanna swing from that bridge, Lise, make some alterations, no problemo, she says.”",
        fifteenth: "Apart from the fact that this could be a sneaky comment from the author to her editors, who were probably reluctant to embrace the difficult title We Go Around In The Night And Are Consumed By Fire, the scene also reflects the inscribing of class identity in language. English schools, which in any case have a bad reputation when it comes to teaching grammar, drum the basic rule into their pupils’ heads that every verb requires a subject. Anyone who leaves before the sixth form will tend to correct such elliptical, but perfectly acceptable constructions as “We go around and are consumed”. One is not born uneducated, but becomes so. And the more someone is affected by this, the more she has to cultivate virtuoso strategies to assert herself. The last word, and the punchline, goes to Sonn here, whose short-sightedness or illiteracy meant that she was not even able to decipher the words in the first place.",
        sixteenth: "But Donna’s gang of lesbians do not only cooperate when it comes to graffiti. They run a flourishing business producing ecstasy and selling it in the leading clubs in their territory. Their business is disguised as a cleaning firm which not only works as a front, but also helps with the acquisition of the necessary chemicals. The gang are disciplined, only breaking the laws they need to break for their business. But there are quite a few of those, given that the business includes defending the gang’s territory and reputation against rival groups. The novel begins with a hair-raising torture scene, in which the women punish a member of another gang for straying too far into their area the previous evening. With a flick-knife and a biro, they spontaneously tattoo a big “C” on the young man’s stomach, while he is tied up. When it comes to the question of what the C stands for, a question which any reader with a passing knowledge of psychoanalysis would not even ask, the characters weigh up the alternatives “cunt” and “Carla” – the one who carved out the sign.",
        seventeenth: "Which leads us to the text’s centre of gravity: Carla. Carla is the mother of Aurora, she is Donna’s most significant relationship, and her household is the headquarters of the gang. Through Carla, the moth logic of the title – whirring through the night and being devoured by flames – becomes a manifesto. Donna remembers sitting in the gloomy, overgrown backyard at Carla’s house:",
        eighteenth: "“I look over at the pot with the clematis that just wouldn’t grow no matter what she [Carla] did. Clematis clitoris she said when we saw it in the garden centre, on account of all the petals were so fragile, and every shade of purple and pink you could think. To me that’s just bad taste, but Carla never gave a toss about stuff like that, just laughed right at me, Hey prudie, she says.",
        nineteenth: "She was proper mad when we got it home and all the petals fell off, reckoned they shouldn’t be allowed to do that, B&Q, selling people stuff that only grows right somewhere else. False Pretences, she called it. It’s too dark here, she said, things need sun.",
        twentieth: "Then I’m thinking about that time we took the bikes all the way to Newquay, just to see Fistral Beach, and how as soon as we got there she stripped right off on the beach not even caring, spinning round and round like a noon, nut-brown and just this black triangle of hair, arms held up to the sun. How I told her Chrissakes at least put your boxers on, they’ll be selling tickets next. How she didn’t want to come home.",
        twentyfirst: "Things need sun.”",
        twentysecond: "Carla is exuberance and vivacity. When it comes to the unwritten rules of the gang, she does not just break more than is necessary, but everything which can be broken – for instance, when she starts an affair with the girlfriend of a rival leader and then unceremoniously kidnaps the woman and her two small children from the gang leader’s flat. Donna may smell trouble, but as always, she makes the plan which is needed to fulfill Carla’s wishes. After the trouble has arrived and Carla is shot in a club, she truly becomes the heart of the story. Everything revolves around the warmth and the danger which Carla represented. As for how Donna goes on to burn her skilful fingers and whether she, Aurora and the others escape at all – rather than spoiling the story here, I urge you to read the book.",
        twentythird: "Leading on from the clematis/clitoris scene, however, we can make out the structure of representation that lends the book its uniqueness. The magic of the novel rests on the tension between hyper-realistic and completely fantastical elements. One dimension which emerges is the detailed analysis of class (and underclass). Grant constructs a study of institutions, both official and outside the law – school, the care system and jail on the one hand, and criminal network, drug gangs and precarious household on the other. Because almost all the characters are female, an unusual focus opens up on a shadowy milieu which is otherwise presented stereotypically. Reproductive work does not take place noiselessly behind the backs of the heroines. It is not just necessary to keep the business running and the enemies in check: there is also a nine-year-old child to look after, along with a grandmother who has become bed-bound from self-negligence and alcoholism.",
        twentyfourth: "That is one side of the story, aligned with the gloomy backyard. On the other hand, the book is suffused with a willful exaggeration of the lesbian dimension. Even if not all the characters are lesbians, the whole world does seem to be lesbian. Bad taste or not – here we understand the clematis through the clitoris, rather than explaining sex via the birds and the bees. Maybe the term “hyperbolic realism” could apply to this. Or “tuned realism” in the sense of “tuning” a car. Surpassing reality with its own means, or: getting something out of it for which it was not constructed. The novel refers to various basic lesbian truths without discussing or translating them further. Donna takes note of the dirty fingernails of a book seller and thinks “she’s either straight or a minger”. The child is brought up lesbian and every town contains well-organised mafia structures which decide the outcome of battles, keep women’s refuges going, and organise weapons. In the finale of the novel, when Donna’s plan works out and her gang not only succeeds in playing off the two rival male gang leaders against each other, but also in luring them to the same remote place, Sonn signals with a torch. From all directions, from behind every hill, a troop of Amazons flashes back.",
        twentyfifthFirst: "“Where’s the back-up, I ask.",
        twentyfifthSecond: "Sonn takes up the torch, flashes once at the dark, then draws and S in the air.",
        twentyfifthThird: "I knock the torch out of her hand, What the fuck are you doing?",
        twentyfifthFourth: "Look, she says.",
        twentyfifthFifth: "All over the hills there’s lights flashing, going off like silent paparazzi bulbs. Each one a zigzag, then an imprint blue in the air that hangs on for a second, just long enough to read. Flash: J. Flash A, flash M, flash P, flash S flash G, flash, flash. Until there’s light and letters appearing everywhere in the dark. Dozens of them.",
        twentysixth: "Then it’s dark again.",
        twentyseventh: "All over the hillside, says Sonn. I can just make out her grin. Next hill too. In case we need them […]. I told you I‘d sort back-up.”",
        twentyeighth: "What a fantastic thought. When there are so many beacons, maybe the moths won’t be consumed any longer: they’ll be held in balance, equidistant from the lights in all directions of the night. Two women can, as Lauretis says, make a lesbian. But only dozens of different lesbian hordes can secure lesbian seeing.",
        twentyninth: "I wanted to offer this scene as the climactic point in the displacement of reality through lesbian universalisation. Where did they all come from – there aren’t even that many of us. And we certainly aren’t so organised that it’s constantly possible to get hold of anything that’s needed and somebody even knows a judge.",
        thirtieth: "But then: what do I know? Is lesbian universalisation really a fantastical stylistic device at this point? Hyperbole? Does it really stand in contrast to the social realistic axis, to the analyses of state institutions of welfare and punishment? Do I have any idea about drug cartels in British cities? About women’s rugby teams? If I can write a text in which only lesbians are quoted, inspired and annotated by a horde of lesbian academics and friends, why shouldn’t a dealer be able to organise her gang in exactly the same way? Why is it unrealistic that she almost exclusively comes across lesbians in her daily life – at least when it comes to the encounters worth mentioning? Where exactly am I sitting, and who am I writing for?",
        thirtyfirst: "Maybe the only really fantastical thing about We Go Around In The Night And Are Consumed By Fire is that lesbian seeing is simply presupposed, rather than being gently mediated. This is not about creating visibility or about outing one character after another. It’s about opening up a completely different space of appearance, in which, if you believe your eyes, you’ll see from a lesbian perspective. Following Cixous, we could say that the threshold of appearance has been crossed, and we have entered the realm of sight. Seen in this light, it is as if we were stumbling around in the dark until now, letting ourselves become fixated on a pitiful few rays of light. Karl Marx (that old lesbian) pointed out long ago that liberal rights to equality are much like Carla’s clematis: they’re no use, as long as there’s too little light in your backyard.",
        thirtysecond: "Click here for Lucy Duggans reply.",
        thirtythird: "This text first appeared in German in the anthology „Lesben raus! Für mehr lesbische Sichtbarkeit.“ (Querverlag 2018, ed. Stephanie Kuhnen)."
    }
}
export {LesbianSeeingContent}